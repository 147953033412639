/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.market-cashflow-value[data-v-75a22586] {
  display: flex;
  padding: 2rem 2rem 10rem;
  background-color: #f3f3f3;
}
.market-cashflow-value.no-data[data-v-75a22586] {
  width: 100%;
  height: 100%;
}
.market-cashflow-value[data-v-75a22586]  .selection-holder {
  width: 40rem !important;
}
.market-cashflow-value .manager-list-wrapper[data-v-75a22586] {
  flex: 0 0 300px;
}
.market-cashflow-value .manager-table-wrapper.table-data[data-v-75a22586] {
  width: calc(100% - 280px);
}
.market-cashflow-value .manager-table-wrapper.no-table-data[data-v-75a22586] {
  width: calc(100% - 280px);
}
.market-cashflow-value .manager-table-wrapper .save-slide-out[data-v-75a22586] {
  z-index: 1;
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.market-cashflow-value .manager-table-wrapper .save-slide-out.show-save[data-v-75a22586] {
  transform: translateY(0rem);
}
.market-cashflow-value .manager-table-wrapper .save-slide-out .slide-out-inner[data-v-75a22586] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.market-cashflow-value .manager-table-wrapper .save-slide-out .slide-out-inner .btn[data-v-75a22586]:first-of-type {
  margin-right: 1rem;
}
.market-cashflow-value .manager-table-content[data-v-75a22586] {
  overflow-x: scroll;
}
.market-cashflow-value .manager-table-content.table-overflow[data-v-75a22586] {
  overflow-x: scroll;
}
.market-cashflow-value .manager-table-content.table-overflow[data-v-75a22586]::-webkit-scrollbar {
  height: 0.6rem;
}
.market-cashflow-value .manager-table-content.table-overflow[data-v-75a22586]::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 0.5rem;
}
.market-cashflow-value .manager-table-content.table-overflow[data-v-75a22586]::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 0.5rem;
}
.market-cashflow-value .add-column[data-v-75a22586] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 16rem;
  padding: 0 0.5rem;
  border: solid white 1px;
  background: #e93f6f0a;
  position: relative;
}
.market-cashflow-value .add-column .add-wrapper[data-v-75a22586] {
  position: sticky;
  top: 50px;
  bottom: 50px;
}
.market-cashflow-value .add-column .add-wrapper div[data-v-75a22586] {
  margin: 0 auto;
}
.market-cashflow-value .add-column span[data-v-75a22586] {
  margin-top: 10px;
  font-size: 13px;
  color: #a1a1a1;
  font-weight: bold;
  text-transform: uppercase;
}
.centerY[data-v-75a22586] {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.bg-grey[data-v-75a22586] {
  background: #f3f3f3;
}
.min-h-100[data-v-75a22586] {
  min-height: 100%;
}
.w-50[data-v-75a22586] {
  width: calc(50% - 160px);
}
.w-70[data-v-75a22586] {
  width: calc(70% - 160px);
}
.w-90[data-v-75a22586] {
  width: calc(90% - 160px);
}
.w-100[data-v-75a22586] {
  width: calc(100% - 160px);
}
.mt-3[data-v-75a22586] {
  margin-top: 2rem;
}
.text-center[data-v-75a22586] {
  text-align: center;
}